<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, maxLength } from "vuelidate/lib/validators";
import { fetchDirectionsListApi } from "@/api/common";
export default {
  page: {
    title: "Nouvelle Direction",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  import: { fetchDirectionsListApi },
  data() {
    return {
      title: "Nouvelle Direction",
      items: [
        {
          text: "Hiérarchie",
          href: "/",
        },
        {
          text: "Directions",
          href: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
      directions: [],
      dirForm: {
        dirName: "",
        dirAdr: "",
        dirAc: "",
        dirIsDr: true,
        parentDir: "",
      },

      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    dirForm: {
      dirName: { required, maxLength: maxLength(64), },
      dirAdr: { required },
      dirAc: { required, maxLength: maxLength(64), },
    },
  },

  mounted() {
    this.fetchDirectionsList();
  },

  methods: {
    fetchDirectionsList(){
      fetchDirectionsListApi()
      .then((res) => (this.directions = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      // if form is valid, submit to api
      var router = this.$router;
      this.$v.$touch()
      if(!this.$v.$invalid){
        this.$http
          .post("/hierarchy/directions/store", this.dirForm)
  
          .then((res) => {
            //Perform Success Action
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                router.push({ name: "hierarchy.directions.index" });
                break;
  
              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            this.$toast.error(error.message);
            // error.response.status Check status code
          })
          .finally(() => {
            //Perform action in always
          });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Désignation</label>
                    <input
                      id="deptName"
                      v-model="dirForm.dirName"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="Mark"
                      :class="{
                        'is-invalid': submitted && $v.dirForm.dirName.$error,
                      }"
                    />
                    <div v-if="submitted && $v.dirForm.dirName.$error" class="invalid-feedback">
                      <span v-if="!$v.dirForm.dirName.required" >Le nom est obligatoire.</span>
                      <span v-if="!$v.dirForm.dirName.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Addresse</label>
                    <input
                      id="validationCustom02"
                      v-model="dirForm.dirAdr"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="Otto"
                      :class="{
                        'is-invalid': submitted && $v.dirForm.dirAdr.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.dirForm.dirAdr.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.dirForm.dirAdr.required"
                        >L'addresse de la division est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Compte analytique</label>
                    <input
                      id="validationCustom03"
                      v-model="dirForm.dirAc"
                      type="text"
                      class="form-control"
                      placeholder=""
                      :class="{
                        'is-invalid': submitted && $v.dirForm.dirAc.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.dirForm.dirAc.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.dirForm.dirAc.required"
                        >Le compte analytique de la division est
                        obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="productdiscountCheck1"
                      switch
                      size="lg"
                      class="mt-2"
                      v-model="dirForm.dirIsDr"
                      >est une Direction Régionale</b-form-checkbox
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6" :class="{'hidden': dirForm.dirIsDr,}">
                  <div class="form-group">
                    <label for="deptName">Direction Mère</label>
                    <select
                      name="parentDir"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.dirForm.dirName.$error,
                        
                      }"
                      v-model="dirForm.parentDir"
                      id=""
                    >
                      <option value=""></option>
                      <option v-for="direction in directions" v-bind:key="direction.id" :value="direction.id" >{{ direction.name }}</option>
                    </select>

                    <div
                      v-if="submitted && $v.dirForm.dirName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.dirForm.dirName.required"
                        >Le nom de la division est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary"
                type="submit"
              >
                Enregistrer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
